<template>
  <div
    v-if="relatedCarousel"
    class="yf-related-product"
  >
    <div class="yf-related-product-heading">
      <h2>{{ relatedCarousel.data.heading }}</h2>
      <p>{{ relatedCarousel.data.description }}</p>
    </div>
    <div class="yf-related-product-listing">
      <div class="yf-related-product-column multi">
        <div
          v-for="(product, i) in mappedProducts.filter((product, index) => product && index < 5)"
          :key="i"
          :class="{
            relatedProduct: true,
            span2: isBigImage(i)
          }"
        >
          <CustomProductCard
            :image="getProductThumbnailImage(product, isBigImage(i) ? 650 : 500, 'j')"
            :title="getProductName(product)"
            :image-alt="getProductImageAlt(product, 'thumbnail')"
            :regular-price="$n(getProductPrice(product).regular, 'currency')"
            :special-price="getProductPrice(product).special && $n(getProductPrice(product).special, 'currency')"
            :omnibus-price="product.omnibus_price ? $n(product.omnibus_price,'currency') : null"
            :colors="getSfColorsFromAttributes(getProductColorAttribute(product))"
            :link="localePath(buildProductUrl(product))"
            :is-added-to-cart="true"
            :is-in-wishlist="!!itemsInWishlist[product.sku]"
            :add-to-cart-disabled="false"
            :show-swatches="showSwatches"
            :sku="getProductMerchantSku(product)"
            :category="product.categories[0].name"
            :full-price-gtm="getPriceFixedDecimals(getProductPrice(product).regular)"
            :price-gtm="getPriceFixedDecimals(getProductPrice(product).special) || getPriceFixedDecimals(getProductPrice(product).regular)"
            @click:wishlist="toggleWishlist(product)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  contentGetters, productGetters, useContent, useProduct,
} from '@vue-storefront/gemini';
import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { getCategoryUidFilter } from '@vue-storefront/gemini/src/getters/categoryGetters';
import { useUrlHelpers } from '~/composables';
import { getSfColorsFromAttributes, getProductColorAttribute, getPriceFixedDecimals } from '~/helpers/product/productData';
import useWishlist from '~/composables/useWishlist';
import CustomProductCard from './CustomProductCard';
import { useI18n } from '../helpers/hooks/usei18n';

export default defineComponent({
  name: 'RelatedProductsCarousel',
  components: {
    CustomProductCard,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    loading: Boolean,
    showSwatches: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const { buildProductUrl } = useUrlHelpers();
    const { locale } = useI18n();
    const identifierHomePageRelatedCarousel = `relatedProductsCarouselHome-${locale}`;
    const relatedCarousel = ref(false);
    const mappedProducts = ref([]);
    const { itemsInWishlist, toggleWishlist, loadItemsFromList } = useWishlist(mappedProducts);
    const { blocks, loadBlocks } = useContent(`cmsBlockRelatedProductsCarousel${identifierHomePageRelatedCarousel}`);

    const {
      products,
      search: relatedProductsSearch,
    } = useProduct('relatedProducts');

    const relatedProductsSearchFilter = {};

    useFetch(async () => {
      await loadBlocks({ identifiers: [identifierHomePageRelatedCarousel] });
      relatedCarousel.value = contentGetters.getCmsBlockContent(blocks.value)[identifierHomePageRelatedCarousel];
      const relatedCarouselCategoryUid = relatedCarousel.value?.data?.category_uid ?? '';
      if (relatedCarouselCategoryUid) {
        relatedProductsSearchFilter.category_uid = getCategoryUidFilter(
          String(`grn:product-list:product-list::${relatedCarouselCategoryUid}`),
        );
      }

      await relatedProductsSearch({
        pageSize: 5,
        currentPage: 1,
        sort: {
          position: 'ASC',
        },
        filter: relatedProductsSearchFilter,
      });
      await loadItemsFromList(products.value.items);
      mappedProducts.value = products.value.items;
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const getProductName = (product) => productGetters.getName(product);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const getProductPrice = (product) => productGetters.getPrice(product);

    return {
      mappedProducts,
      productGetters,
      blocks,
      relatedCarousel,
      buildProductUrl,
      getSfColorsFromAttributes,
      getProductColorAttribute,
      getPriceFixedDecimals,
      getProductThumbnailImage: productGetters.getProductThumbnailImage,
      getProductName,
      getProductPrice,
      getProductImageAlt: productGetters.getProductImageAlt,
      getProductMerchantSku: productGetters.getProductMerchantSku,
      itemsInWishlist,
      toggleWishlist,
    };
  },
  methods: {
    isBigImage(i) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return i === 2;
    },
    // toggleWishlist(index) {
    //   this.mappedProducts[index].isInWishlist = !this.mappedProducts[index].isInWishlist;
    //   this.$forceUpdate();
    // },
  },
});
</script>

<style lang="scss" scoped>
.yf-related-product {
  max-width: 1414px;
  margin: 0 auto;
  padding: 0 84px 135px 84px;
}
.yf-related-product-column {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 24px;
}

.yf-related-product-listing {
  margin-left: -12px;
  margin-right: -12px;
}

.relatedProduct {
  padding-left: 12px;
  padding-right: 12px;
  // padding-bottom: 24px;
}
.span2 {
  grid-row: span 2 / span 2;
  grid-column: span 2 / span 2;
  .yf-custom-product-card {
    // margin-right: -24px;
  }
}

body .yf-related-product-heading {
  margin-bottom: 78px;
  margin-top: 40px;
  text-align: center;

  h2 {
    color: #141e16;
    font-family: 'Frank Ruhl Libre';
    font-size: 50px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 46px;
    margin-bottom: 14px;
    text-align: center;
  }

  p {
    color: #141e16;
    font-family: 'Spartan';
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    margin: 0;
  }
}

@media ( max-width: 768.98px) {
  .yf-related-product {
    max-width: 1246px;
    margin: 0 auto;
    margin-top: 6px;
    padding-bottom: 84px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .yf-related-product-listing {
    margin-left: 0;
    margin-right: 0;
  }

  .yf-related-product-column {
      grid-template-columns: 1fr 1fr;
      grid-gap: 11px 8px;
  }

  .relatedProduct {
    padding: 0;
  }
  .span2 {
    grid-row: span 2 / span 2;
    grid-column: span 2 / span 2;
    // padding: 0;
    .yf-custom-product-card {
      margin-right: 0;
    }
  }

  body .yf-related-product-heading {
    margin: 18px 0 24px;
    text-align: unset;

    h2 {
      font-size: 28px;
      line-height: 34px;
      text-align: inherit;
      margin-bottom: 12px;
    }

    p {
      font-size: 12px;
      text-align: inherit;
    }
  }
}
</style>

<style lang="scss">
.yf-related-product .sf-image {
  --image-width: 100%;
}

.yf-related-product {
  .sf-image--wrapper {
    height: 100%;
    display: flex;
    width: 100%;
  }

  .yf-custom-product-card {
    height: 100%;
  }

  .yf-custom-product-card {

    .image-link {
      height: 100%;
    }
    .yf-product-content {
      text-align: center;
      padding-bottom: 6px;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: var(--yf-product-content);
      transition: all .2s ease-in-out;
      opacity: var(--yf-product-content-opacity);
      visibility: var(--yf-product-content-visibility);
      pointer-events: var(--yf-product-content-events);
      transition-property: opacity;
    }
    .sf-color:hover {
      transform: scale(1.667);
      --color-box-shadow-opacity: 0.25;
    }

    .sf-product-card__price {
      margin-top: 8px;
    }

    .sf-product-card {
      --yf-product-content-transform : translateX(-100%);
      --yf-product-content-opacity: 0;
      --yf-product-content-visibility: hidden;
      --yf-product-content-events: none;
      --product-card-wishlist-icon-opacity: 0;
      &:hover {
        --product-card-box-shadow: unset;
        --yf-product-content-transform : translateX(0);
        --yf-product-content-opacity: 1;
        --yf-product-content-visibility: visible;
        --yf-product-content-events: all;
        --product-card-wishlist-icon-opacity: 1;
      }
      .sf-product-card__title {
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: 13px;
        margin-bottom: 0;
      }
    }
  }

  .sf-product-card,
  .sf-product-card__image-wrapper,
  .sf-product-card__image-wrapper > .sf-product-card__link {
    height: 100%;
  }

}

@media ( max-width: 768.98px) {
  .yf-related-product {
    .yf-custom-product-card {
      .sf-product-card {
        --product-card-box-shadow: unset;
        --yf-product-content-transform : translateX(0);
        --yf-product-content-opacity: 1;
        --yf-product-content-visibility: visible;
        --yf-product-content-events: all;
        --product-card-wishlist-icon-opacity: 1;
        .sf-product-card__title {
          margin-top: 6px;
        }
      }
      .yf-product-content {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 6px;
      }
    }
  }
}

</style>
